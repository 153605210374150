import UserRole from '../models/api/users/UserRole'
import PagedFilterBase from "./PagedFilterBase";

export default class Userfilter extends PagedFilterBase {
    Email?: string;
    FirstName?: string;
    LastName?: string;
    Role?: UserRole;
    BranchId?: string;

    constructor(init?: Partial<Userfilter>) {
        super()

        if (init) Object.assign(this, init);
    }
}
